'use strict';

module.exports = function () {
    $(document).on('click', '.print-label-actual', function (e) {
        e.preventDefault();
        var labelURL = $('#label-url').data('url');
        if (labelURL) {
            window.open(labelURL, '_blank').focus();
        }
    });
};
