'use strict';

module.exports = function () {
    $(document).on('click', '.atome-logo-popup', function () {
        var dialogClassName = '';
        if ($(window).width() < 960) {
            dialogClassName += ' full-width-dialog';
        }
        $("#atome-popup-show").dialog({
            modal: true,
            resizable: false,
            width: '100%',
            dialogClass: dialogClassName
        });
    });
};