require('@apac/js/app');
require('@apac/js/pages/checkout/summary');
var bankTransfer = require('./bankTransfer');
var validator = require('./validator');
var cartPage = require('./pages/cart');
var autoComplete = require('./autocomplete');
var validatorAU = require('../../../app_rlau_core/cartridge/js/validator');
var eform = require("./../../../app_rlau_core/cartridge/js/eform");
var profilePage = require("./pages/profile");
var billingPage = require("./pages/checkout/billing");
var returnPage = require('./pages/return');
var atome = require('./atome');
var tooltip = require('@apac/js/tooltip');

jQuery(function() {
    bankTransfer();
    validator();
    cartPage();
    autoComplete();
    eform();
    validatorAU();
    profilePage();
    billingPage();
    returnPage();
    atome();
    tooltip.init();
});