'use strict';

module.exports = function () {
    $(document).on('click', '.show_billing_add_address, .edit-this-address, label[for$="_useAsBillingAddress"]', function () {
        var $formAddress = $('.checkout-billing');

        $formAddress.find('input[name$="_postal"]').attr('placeholder', Resources.POST_CODE + " *");
    });

    $(document).on('change', 'input[name$="_useAsBillingAddress"]', function () {
        var $formAddress = $('.dyn-form-billing');

        $formAddress.find('input[name$="_postal"]').attr('placeholder', Resources.POST_CODE + " *");
    });

    $(document).ready(function() {
        var $formAddress = $('.checkout-billing');

        $formAddress.find('input[name$="_postal"]').attr('placeholder', Resources.POST_CODE + " *");
    });

    if (window.dw.applepay && window.ApplePaySession && window.ApplePaySession.canMakePayments() && typeof fetch === 'function') {
        $('.is-APPLEPAY').removeClass('hide');
        $('.is-DW_APPLE_PAY').removeClass('hide');
    } else {
        $('.is-APPLEPAY').addClass('hide');
        $('.is-DW_APPLE_PAY').addClass('hide');
    }
};
