'use strict';

module.exports = function () {
    var validateNumericPhone = function (value, el) {
        var nameRegEx = /^(\s*|\d+)$/;
        return nameRegEx.test(value);
    };

    var validatePhoneSg = function (value, el) {
        var rgx = /^$|^(065(\d{7}|\d{8}))$|^(65(\d{8}|\d{9}))$/;
        var form = $(el).closest("form");

        if ((form.find('select[name$=_addressFields_country]').val() && form.find('select[name$=_addressFields_country]').val() !== 'SG') || (form.find('select[name$=_address_country]').val() && form.find('select[name$=_address_country]').val() !== 'SG')) {
            return true;
        }

        if (form.hasClass("eform-form")) {
            rgx = /^\d{7,20}$/;
        }
        var isValid = rgx.test(value);
        return isValid;
    };

    var validatePhoneMy = function (value, el) {
        var rgx = /^$|^(060(\d{7}|\d{8}|\d{9}))$|^(60(\d{8}|\d{9}|\d{10}))$/;
        var form = $(el).closest("form");

        if ((form.find('select[name$=_addressFields_country]').val() && form.find('select[name$=_addressFields_country]').val() !== 'MY') || (form.find('select[name$=_address_country]').val() && form.find('select[name$=_address_country]').val() !== 'MY')) {
            return true;
        }

        if (form.hasClass("eform-form")) {
            rgx = /^\d{7,20}$/;
        }
        var isValid = rgx.test(value);
        return isValid;
    };

    var validateZipCodeSg = function (value, el) {
        var $form = $(el).closest("form");
        if ($form.find('[id$="_country"]').val() !== "SG") {
            return true;
        }

        var rgx = /^\d{6}$/;
        var isValid = rgx.test(value);
        return isValid;
    };

    var validateZipCodeMy = function (value, el) {
        var $form = $(el).closest("form");
        if ($form.find('[id$="_country"]').val() !== "MY") {
            return true;
        }

        var rgx = /^([0-9]){5,9}$/;
        var isValid = rgx.test(value);

        if (!isValid) {
            var $suburb = $form.find('select[id$="_city"]');
            $suburb.addClass('invalidPostCode').removeClass('required');
            $suburb.val('').trigger('change').trigger('click');
        }

        return isValid;
    };

    var getGCamount = function (value) {
        var thisVal = value;

        if(thisVal.endsWith('.00') || thisVal.endsWith(',00')) {
            thisVal = thisVal.slice(0, -3);
        }

        if (/^[S$]/.test(thisVal)) {
            thisVal = thisVal.replace(/^(S\$)/, '').replace(/(\.)*/g, '').replace(/(,)*/g, '');
        }

        if (/^[RM]/.test(thisVal)) {
            thisVal = thisVal.replace(/^(RM)/, '').replace(/(\.)*/g, '').replace(/(,)*/g, '');
        }

        return thisVal;
    };

    var isSpChar = function (str) {
        var isValid;
        var spCharCounter = 0;

        for (var i = 0; i < str.length; i++) {
            if (str.charAt(i) == '.' || str.charAt(i) == ',') {
                spCharCounter++;
            }
        }
        //To be a valid number, there should be either 1 or 0 special characters
        if (spCharCounter > 1) {
            isValid = false;
        } else {
            isValid = true;
        }
        if (isValid) {
            return parseFloat(str.replace(/ |,/g, function ($0) {
                return $0 == ',' ? '.' : '';
            }));
        }
        return null;
    };

    var validateRange = function (val, min, max) {
        return (val >= min && val <= max);
    };

    var validateGiftcertAmount = function (value, el) {
        $(el).val(value);
        var isOptional = this.optional(el);
        var thisAmount = getGCamount(value);
        var amount = isSpChar(thisAmount);
        var amountRegEx = /^[0-9.,]+$/;
        var isValid = !isNaN(amount) && parseInt(amount) === amount && amountRegEx.test(amount);
        return isOptional || isValid;
    };
    var validateGiftcertRange = function (value, el) {
        $(el).val(value);
        var isOptional = this.optional(el);
        var thisAmount = getGCamount(value);
        var amount = isSpChar(thisAmount);
        var isNotDecimal = /\d+\.?\d*/.test(amount);
        var isValid = false;
        if (isNotDecimal) {
            isValid = validateRange(thisAmount, 5, 1000);
        } 
        return isOptional || isValid;
    };

    var validateAddressSg = function (value, el) {
        var rgx = /[`~<>;#$@|':"/[\]|{}=_+]/;
        if (rgx.test(value)) {
            return false;
        } else {
            if (value.includes('\\')) {
                return false;
            } else {
                return true;
            }
        }
    };

    var regexValidate = (function (value, rgx, notCheckEmpty) {
        if (notCheckEmpty && !value) {
            return true;
        }
        if (!rgx){
            rgx = /^[A-Za-z\u3000\u3400-\u4DBF\u4E00-\u9FFF\s]+$/;
        }
        var isValid = rgx.test(value);
        return isValid;
    });

    function validateCityByPostcode(value, el) {
        var valid = true;
        var country = $(el).closest('form').find('.country').val();
        if (country == "MY" && $(el).hasClass("invalidPostCode")) {
            valid = false;
        }
        return valid;
    }


    $.validator.addMethod('check_length_chinese_chars', function (value, el) {
        return (new TextEncoder().encode(value).length) > 35 ? false : true;
    }, Resources.VALIDATE_MAXLENGTH.replace("{0}", 35));
    
    
    var firstStateOption = $('#dwfrm_singleshipping_shippingAddress_addressFields_states_state').find('option:first');
    if( firstStateOption.length > 0 && firstStateOption.val().toLowerCase() == Resources.STATE_LABEL.toLowerCase() )   {
        firstStateOption.val('');
    }


    $.validator.addMethod('checkNumericPhone', validateNumericPhone, Resources.REQUIRED_NUMBER);
    $.validator.addMethod('phone_sg', validatePhoneSg, Resources.INVALID_PHONE);
    $.validator.addMethod('phone_my', validatePhoneMy, Resources.INVALID_PHONE);
    $.validator.addMethod('city', validateCityByPostcode, Resources.INVALID_POSTCODE_TO_CITY);
    $.validator.addMethod('zipcode_sg', validateZipCodeSg, Resources.INVALID_ZIPCODE);
    $.validator.addMethod('zipcode_my', validateZipCodeMy, Resources.INVALID_ZIPCODE);
    $.validator.addMethod('giftcertAmount', validateGiftcertAmount, Resources.VALIDATE_INCORRECTCHARACTERSS);
    $.validator.addMethod('giftcertAmountRange', validateGiftcertRange, function (params, element) {
        var isNotDecimal = /\d+\.?\d*/.test($(element).val());
        return isNotDecimal ? Resources.GIFT_CERT_AMOUNT_INVALID : Resources.VALIDATE_INCORRECTCHARACTERSSDECIMAL;
    });
    $.validator.addMethod('address_sg', validateAddressSg, Resources.VALIDATE_INCORRECTADDRESSFORMAT);

    $.validator.addMethod('firstName', function (value, el) {
        var isValid = regexValidate(value);
        return isValid;
    }, Resources.VALIDATE_INCORRECTCHARACTERSS);

    $.validator.addMethod('lastName', function (value, el) {
        var rgx = /^[A-Za-z0-9\u3000\u3400-\u4DBF\u4E00-\u9FFF\s]+$/;
        var isValid = regexValidate(value, rgx, false);
        return isValid;
    }, Resources.VALIDATE_INCORRECTCHARACTERSS);

    $.validator.addMethod('firstname', function (value, el) {
        var isValid = regexValidate(value, null, true);
        return isValid;
    }, Resources.VALIDATE_INCORRECTCHARACTERSS);

    $.validator.addMethod('lastname', function (value, el) {
        var rgx = /^[A-Za-z0-9\u3000\u3400-\u4DBF\u4E00-\u9FFF\s]+$/;
        var isValid = regexValidate(value, rgx, true);
        return isValid;
    }, Resources.VALIDATE_INCORRECTCHARACTERSS);
    
    // remove select default state
    
};
