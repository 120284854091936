'use strict';
require("./libs/jquery.disableautofill");

module.exports = function () {
    $(".input-text").each(function( ) {
        $(this).prop('autocomplete', 'new-autocomplete');
    });
    $("form").each(function( ) {
        $(this).prop('autocomplete', 'new-autocomplete');
    });

    $(document).on('focus', ".input-text", function () {
        $(this).prop('autocomplete', 'new-autocomplete');
        $(this).disableAutoFill();
    });

    $(document).on('accountAddress:turnoffAutocomplete', function () {
        $(".input-text").each(function( ) {
            $(this).prop('autocomplete', 'off');
            $(this).disableAutoFill();
        });

        $("form").each(function( ) {
            $(this).prop('autocomplete', 'new-autocomplete');
        });
    });

    var removeSpecialChars = function (field) {
        var $form = $(field).closest('form');
        if ($form.attr('autocomplete') && $form.attr('autocomplete') == "new-autocomplete") {
            var $address1 = $('#dwfrm_singleshipping_shippingAddress_addressFields_address1, #dwfrm_profile_address_address1, #dwfrm_orderreturn_pickUpAddress_addressFields_address1');
            var $address2 = $('#dwfrm_singleshipping_shippingAddress_addressFields_address2, #dwfrm_profile_address_address2, #dwfrm_orderreturn_pickUpAddress_addressFields_address2');
            var $city = $('#dwfrm_singleshipping_shippingAddress_addressFields_city, #dwfrm_profile_address_city, #dwfrm_orderreturn_pickUpAddress_addressFields_city');
            var $state = $('#dwfrm_singleshipping_shippingAddress_addressFields_states_state, #dwfrm_profile_address_states_state, #dwfrm_orderreturn_pickUpAddress_addressFields_states_state');
            if ($('#EDQCharactersFilterPattern').length && $('#EDQCharactersFilterPattern').val().length > 0) {
                var rgx = new RegExp($('#EDQCharactersFilterPattern').val(), 'g');
                $address1.val($address1.val().replace(rgx, ''));
                $address2.val($address2.val().replace(rgx, ''));
                $city.val($city.val().replace(rgx, ''));
                $state.val($state.val().replace(rgx, ''));
                $address1.valid();
                $address2.valid();
                $city.valid();
                $state.valid();
            }
        }
    };

     $(document).on('change blur input', 'input[name$="_address1"]', function () {
        removeSpecialChars(this);
    });
};
