'use strict';

module.exports = function () {
    $(document).on('click', '.address-edit, .address-create', function () {
        $('.ui-dialog').trigger('focus');
    });

    $(document).on('focus', '.ui-dialog', function() {
        if (!$(this).find('#edit-address-form').length) {
            return;
        }

        setTimeout(function() {
            var $formAddress = $('#edit-address-form');

            $formAddress.find('input[name$="_postal"]').attr('placeholder', Resources.POST_CODE + " *");
        }, 100);
    });

    // $(document).on('focusin focusout click blur', '[name$="_state"]', function() {
    //     var $form = $('form');
    //     var $country = $form.find('select[id$="_country"]');
    //     var country = $country.val();

    //     if (country == 'SG') {
    //         $form.find('[name$="_state"]').removeClass('required').closest('.form-row').removeClass('required').find('label .required-indicator').remove();
    //     }
    // });
};
